/**
 * These theme values will override the base theme.
 *
 * We're passing these values to Layout component;
 * The Layout component merges these values with the
 * base theme.
 *
 */

const theme = {
    colors: {
      background: `#FCFCFF`,
      headerBg: `transparent`,
      footerBg: `#F6F7FA`
    }
  }

 export default theme
